import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import fightingCovid from '../../assets/images/fighting-coronavirus/fighting-coronavirus-innovations-opaque.png'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
import disqualify from '../../assets/images/disqualify.png'

import icon1 from '../../assets/images/services/service-icon1.png'
import icon2 from '../../assets/images/services/service-icon2.png'
import icon3 from '../../assets/images/services/service-icon3.png'

const CovidDetailsContent = () => {
    return (
        <section className="services-area ptb-100">
            <div className="container">
                <br />
                <br />
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <p class="text-center">
                            <strong>Our special terms cover both IT Consulting and Talent Scouting Services!</strong>
                            <br/> <Link to="/contact">Reach out via web chat, email or give us a call</Link>
                        </p>
                    </div>
                </div>
                <br />
                
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="main-banner-image">
                                <img src={fightingCovid} alt="banner" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="main-banner-content">
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>Let's Punch It!</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>Stand together with us against global pandemic!
                                <br />It's not an easy time and we feel your pain like ours!
                                <br />We value your commitment and effort!
                                <br />Read below to find out more!
                                </p>
                            </ReactWOW>
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <Link to="/contact" className="default-btn">
                                        <i className="flaticon-right"></i> 
                                        Get In Touch <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>

                        </div>
                    </div>
                </div>
            </div>
<br />
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <h3>
                              
                                    Extended Invoice Periods
                              
                            </h3>
                            <p>We provide extended 90-days invoice periods for trusted clients</p>

{/*
                            <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>
*/}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <h3>
                                
                                    Service Discounts
                                
                            </h3>
                            <p>Making effort or donating? <br />Let us know and get a discount!</p>
{/*
                            <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>
*/}
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <h3>
                        {/*
                                <Link to="/service-details">
                                */}
                                    We donate! Join us!
                                    {/*
                                </Link>
                                */}
                            </h3>
                            <p>We contribute to RKI and Malteser! <br /> Join us!</p>
                            
{/*
                            <Link to="/service-details" className="read-more-btn">
                                Read More <i className="flaticon-right"></i>
                            </Link>
*/}
                        </div>
                    </div>


                </div>
            </div>
        </section>
    )
}

export default CovidDetailsContent
